<template>
  <div>
    <b-card-code no-body v-if="acesso.CadastroGrupoFolhaConsulta">
      <b-card-body>
        <b-row>
          <b-col md="2">
            <b-form-group label="Pesquisar Por" label-for="opcao">
              <b-form-select id="opcao" :options="tipoOpcoes" v-model="pesquisar.tipoOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                placeholder="Descrição"
                v-model="pesquisar.descricao"
                v-on:keydown.enter="carregarGrid"
              />
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button
              variant="primary"
              @click="pesquisarGrupoFolha()"
              :disabled="carregando"
              style="margin-top: 21px; width: 160px"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
          <b-col md="auto" align="right" v-show="this.acesso.CadastroGrupoFolhaCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="novoRegistro()"
            >
              Novo Cadastro
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="auto" align="right" v-show="!this.acesso.CadastroOrgaoConsulta && this.acesso.CadastroOrgaoCriarNovo">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="novoRegistro()"
            >
              Novo Cadastro
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>

      <b-table
        v-show="this.totalRows > 0"
        striped
        responsive
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy="isBusy"
        :current-page="pesquisar.currentPage"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template #cell(opcoes)="row">
          <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>
            <b-dropdown-item @click="editar(row.item.id)" v-show="acesso.CadastroGrupoFolhaEditar">
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item @click="authEExclui(row.item.id)" v-show="acesso.CadastroGrupoFolhaExcluir">
              <feather-icon icon="XIcon" size="16" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(instituto)="row">
          {{ row.item.instituto.nome }}
        </template>
      </b-table>

      <b-card-body v-if="this.totalRows > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Por Página"
          style="align-items: center"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="pesquisar.currentPage"
          :total-rows="totalRows"
          :per-page="pesquisar.perPage"
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>

      <!-- Modal Cadastro -->
      <b-modal id="modal-cadastro" ref="modal-cadastro" centered size="lg" title="Cadastro" no-close-on-backdrop>
        <validation-observer ref="formOrgao">
          <b-form>
            <b-tabs>
              <b-tab>
                <template #title>
                  <feather-icon icon="HomeIcon" />
                  <span>Dados do Grupo</span>
                </template>
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Órgão" label-for="orgao">
                      <validation-provider #default="{ errors }" name="orgao" rules="required">
                        <v-select-pt
                          name="orgao"
                          v-model="dados.orgaoId"
                          :options="orgaos"
                          :reduce="(option) => option.id"
                          label="nome"
                        ></v-select-pt>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nome do Grupo" label-for="nome">
                      <validation-provider #default="{ errors }" name="Nome do Grupo" rules="required">
                        <b-form-input id="nome" type="text" placeholder="Nome do Grupo" v-model="dados.nomeDoGrupo" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Abreviatura" label-for="abreviatura">
                      <b-form-input
                        id="abreviatura"
                        type="text"
                        placeholder="Abreviatura"
                        v-model="dados.abreviatura"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Código do Cliente" label-for="codigoCliente">
                      <validation-provider #default="{ errors }" name="Código do Cliente" rules="required">
                        <b-form-input
                          id="codigoCliente"
                          type="number"
                          placeholder="Código do Cliente"
                          v-model="dados.codigoDoCliente"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Dia de Fechamento da Folha" label-for="diaFechamentoFolha">
                      <validation-provider
                        #default="{ errors }"
                        name="Dia de Fechamento da Folha"
                        rules="required|min_value:1|max_value:31"
                      >
                        <cleave
                          id="diaFechamentoFolha"
                          type="number"
                          placeholder="Dia de Fechamento da Folha"
                          v-model="dados.diaFechamentoFolha"
                          :raw="false"
                          :options="options.cleaveDia"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Dia de Repasse" label-for="diaDeRepasse">
                      <validation-provider
                        #default="{ errors }"
                        name="Dia de Repasse"
                        rules="required|min_value:1|max_value:31"
                      >
                        <cleave
                          id="diaDeRepasse"
                          type="number"
                          placeholder="Dia de Repasse"
                          v-model="dados.diaDeRepasse"
                          :raw="false"
                          :options="options.cleaveDia"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Repassa no Mesmo Mês de Vencimento"
                      label-for="repassaNoMesmoMesDeVencimento"
                      v-b-tooltip.hover.bottom="
                        'Ao habilitar o repasse será considerado no mesmo mês de vencimento da parcela.'
                      "
                    >
                      <validation-provider #default="{ errors }" name="Repassa no Mesmo Mês de Vencimento">
                        <span>Não </span>
                        <b-form-checkbox
                          checked="false"
                          class="custom-control-primary"
                          v-model="dados.repassaNoMesmoMesDeVencimento"
                          name="repassaNoMesmoMesDeVencimento"
                          id="repassaNoMesmoMesDeVencimento"
                          switch
                          inline
                        />
                        <span>Sim</span>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-form>
        </validation-observer>
        <template #modal-footer="{}">
          <b-button variant="outline-secondary" @click="$bvModal.hide('modal-cadastro')"> Fechar </b-button>
          <b-button variant="primary" @click="salvar" :disabled="carregando">
            {{ !carregando ? 'Salvar' : '' }}
            <b-spinner small v-if="carregando" />
          </b-button>
        </template>
      </b-modal>

      <!-- INICIO DO VERIFICA SENHA -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card-code>
    <b-card class="text-center" v-else>
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para realizar pesquisas.</b-card-text>
        <b-card-text class="mb-1"
          >Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.</b-card-text
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber, Ufs } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      AutenticaSenha,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },
        carregando: false,
        required,
        number: {},
        orgaos: [],
        tipoOpcoes: [
          { value: 1, text: 'Nome do Grupo' },
          { value: 2, text: 'Abreviatura' },
          { value: 3, text: 'Código do Cliente' },
        ],
        poderOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'Executivo', text: 'Executivo' },
          { value: 'Legislativo', text: 'Legislativo' },
          { value: 'Judiciario', text: 'Judiciário' },
          { value: 'MinisterioPublico', text: 'Ministério Público' },
          { value: 'Defensoria', text: 'Defensoria' },
        ],
        esferaOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'Federal', text: 'Federal' },
          { value: 'Estadual', text: 'Estadual' },
          { value: 'Municipal', text: 'Municipal' },
        ],
        responsavelCidade: [],
        ufOpcoes: Ufs,
        options: {
          cleaveDia: {
            numeral: true,
            numeralIntegerScale: 2,
            delimiter: '',
            blocks: [2],
          },
          cleaveCodigo: {
            numeral: true,
            numeralIntegerScale: 2,
            delimiter: '',
            blocks: [2],
          },
        },
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'opcoes', label: 'Opções' },
          {
            key: 'codigo',
            label: 'Nº Contrato',
            sortable: true,
            formatter: (value, key, item) => {
              return String(value).padStart(6, '0')
            },
          },
          { key: 'nomeDoGrupo', label: 'Nome do Grupo', sortable: true },
          { key: 'abreviatura', label: 'Abreviatura', sortable: true },
          {
            key: 'diaFechamentoFolha',
            label: 'Dia Fechamento Folha',
            sortable: true,
          },
          {
            key: 'diaDeRepasse',
            label: 'Dia de Repasse',
            sortable: true,
          },
        ],
        items: [],
        dados: {},
        acesso: {
          CadastroGrupoFolhaConsulta: false,
          CadastroGrupoFolhaCriarNovo: false,
          CadastroGrupoFolhaEditar: false,
          CadastroGrupoFolhaExcluir: false,
        },
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.CadastroGrupoFolhaConsulta = true
        this.acesso.CadastroGrupoFolhaCriarNovo = true
        this.acesso.CadastroGrupoFolhaEditar = true
        this.acesso.CadastroGrupoFolhaExcluir = true
      } else {
        this.acesso.CadastroGrupoFolhaConsulta = this.userData.GrupoAcesso.CadastroGrupoFolhaConsulta
        this.acesso.CadastroGrupoFolhaCriarNovo = this.userData.GrupoAcesso.CadastroGrupoFolhaCriarNovo
        this.acesso.CadastroGrupoFolhaEditar = this.userData.GrupoAcesso.CadastroGrupoFolhaEditar
        this.acesso.CadastroGrupoFolhaExcluir = this.userData.GrupoAcesso.CadastroGrupoFolhaExcluir
      }
      // Conforme reunião retiramos a consulta automática
      // this.carregarGrid();
    },
    methods: {
      pesquisarGrupoFolha() {
        this.pesquisar = { ...this.pesquisar, currentPage: 1 }
        this.carregarGrid()
      },
      carregarGrid() {
        this.isBusy = true
        useJwt
          .pesquisar('cadastro/grupoFolha/GetPesquisar', {
            ...this.pesquisar,
            institutoSelecionado: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.isBusy = false
            this.items = response.data
            this.totalRows = response.data.length

            if (!response.data) this.$message.error('Nenhum registro foi encontrado!')
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarOrgaos() {
        useJwt
          .get(`cadastro/orgao`)
          .then((response) => {
            this.orgaos = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
      novoRegistro() {
        this.carregarOrgaos()
        this.dados = {}
        this.$refs['modal-cadastro'].show()
      },
      authEExclui(item) {
        this.items.item = item
        this.items.mode = 'authEExclui'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authEExclui') {
            this.excluir(this.items.item)
          }
        }
      },
      excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir o cadastro selecionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .delete(`cadastro/grupoFolha`, item)
              .then((response) => {
                this.carregarGrid()
              })
              .catch((error) => {
                console.error(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erro: Registro possui relacionamentos!',
                      icon: 'BellIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
              })
          }
        })
      },
      editar(item) {
        this.carregarOrgaos()
        useJwt
          .edit(`cadastro/grupoFolha`, item)
          .then((response) => {
            this.dados = response.data
            this.$refs['modal-cadastro'].show()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      salvar(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formOrgao.validate().then((success) => {
          if (success) {
            this.isBusy = true
            let dados = this.dados
            dados.codigoDoCliente = formatarParaNumber(dados.codigoDoCliente)
            dados.diaFechamentoFolha = formatarParaNumber(dados.diaFechamentoFolha)
            dados.diaDeRepasse = formatarParaNumber(dados.diaDeRepasse)
            dados.institutoId = this.userData.institutoSelecionado

            if (!this.dados.id) {
              this.cadastrarGrupo(dados)
            } else {
              this.atualizarGrupo(dados)
            }
          } else {
            this.isBusy = false
            this.msgError('Erro: Há campos não preenchidos no formulário!')
          }
        })
      },
      cadastrarGrupo(dados) {
        useJwt
          .post(`cadastro/grupoFolha`, dados)
          .then((response) => {
            this.$swal({
              title: 'Atenção!',
              text: 'Cadastrado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.isBusy = false
                this.$refs['modal-cadastro'].hide()
                this.carregarGrid()
              }
            })
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: error.response.data.error,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 8000,
              },
            )
          })
      },
      atualizarGrupo(dados) {
        const { id, ...dadosAtualizacao } = dados
        useJwt
          .update(`cadastro/grupoFolha`, this.dados.id, dadosAtualizacao)
          .then((response) => {
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.isBusy = false
                this.$refs['modal-cadastro'].hide()
                this.carregarGrid()
              }
            })
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: error.response.data.error,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 8000,
              },
            )
          })
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
